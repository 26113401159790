var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "drawer-layout",
    {
      attrs: {
        title: _vm.setTitle,
        show: _vm.open,
        isForm: _vm.isForm,
        permission: "organization:user:list:button:edit",
      },
      on: {
        show: _vm.show,
        closed: _vm.closed,
        save: _vm.saveForm,
        edit: _vm.edit,
      },
    },
    [
      _c(
        "div",
        { staticClass: "info-group" },
        [
          _c("g-title", [_vm._v("基本信息")]),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "名称" } },
            [_vm._v(_vm._s(_vm.curRow.nickName))]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "登录邮箱" } },
            [_vm._v(_vm._s(_vm.curRow.email))]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "所属部门" } },
            [
              _c(
                "div",
                { staticClass: "flex flex-wrap pt-1" },
                _vm._l(_vm.curRow.departs, function (item, index) {
                  return _c("a-tag", { key: index, staticClass: "mr-3 mb-3" }, [
                    _vm._v(_vm._s(item.departName)),
                  ])
                }),
                1
              ),
            ]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "职位" } },
            [
              _c(
                "div",
                { staticClass: "flex flex-wrap pt-1" },
                _vm._l(_vm.curRow.positions, function (item, index) {
                  return _c("a-tag", { key: index, staticClass: "mr-3 mb-3" }, [
                    _vm._v(_vm._s(item.name)),
                  ])
                }),
                1
              ),
            ]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "角色" } },
            [
              _c(
                "div",
                { staticClass: "flex flex-wrap pt-1" },
                _vm._l(_vm.curRow.roles, function (item, index) {
                  return _c("a-tag", { key: index, staticClass: "mr-3 mb-3" }, [
                    _vm._v(_vm._s(item.roleName)),
                  ])
                }),
                1
              ),
            ]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "适用工具" } },
            [
              _c(
                "div",
                { staticClass: "flex flex-wrap pt-1" },
                [
                  _vm._l(_vm.onlyValue, function (item, index) {
                    return [
                      _vm.curRow[item.value]
                        ? _c(
                            "a-tag",
                            { key: index, staticClass: "mr-3 mb-3" },
                            [_vm._v(_vm._s(item.label))]
                          )
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ]
          ),
          _c("g-title", [_vm._v("个人档案")]),
          _vm.curRow.userFile
            ? _c(
                "div",
                [
                  _c(
                    "info-item",
                    { attrs: { "label-width": _vm.labelWidth, label: "头像" } },
                    [
                      _c("img", {
                        staticClass: "upload_img",
                        attrs: { src: _vm.curRow.userFile.avatar },
                      }),
                    ]
                  ),
                  _c(
                    "info-item",
                    { attrs: { "label-width": _vm.labelWidth, label: "姓名" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.curRow.userFile.userName) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    { attrs: { "label-width": _vm.labelWidth, label: "性别" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.curRow.userFile.sex == 1 ? "男" : "女") +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "出生日期",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.curRow.userFile.bornDate) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    { attrs: { "label-width": _vm.labelWidth, label: "国籍" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.curRow.userFile.country) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    { attrs: { "label-width": _vm.labelWidth, label: "民族" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.curRow.userFile.nation) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: { "label-width": _vm.labelWidth, label: "证件号" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.curRow.userFile.idCardNo) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    { attrs: { "label-width": _vm.labelWidth, label: "QQ号" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.curRow.userFile.qq) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "电话号码",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.curRow.userFile.phone) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    { attrs: { "label-width": _vm.labelWidth, label: "学历" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.curRow.userFile.education) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    { attrs: { "label-width": _vm.labelWidth, label: "学校" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.curRow.userFile.school) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    { attrs: { "label-width": _vm.labelWidth, label: "证书" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.curRow.userFile.certificate) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "工作经历",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.curRow.userFile.workExperience) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "工作地城市",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.curRow.userFile.workCity) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "签约类型",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.curRow.userFile.contractType) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "签约日期",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.curRow.userFile.contractDate) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "是否有试用期",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.curRow.userFile.haveProbation ? "是" : "否"
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "试用期结束日期",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.curRow.userFile.probationEndDate) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "是否有全职工作",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.curRow.userFile.workStatus == 1 ? "是" : "否"
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "可工作日期",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.parseWorkDays(_vm.curRow.userFile.workDays)
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "工作日每天可安排数量",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.curRow.userFile.workdayAvailableCount) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "周末两天可安排数量",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.curRow.userFile.weekendAvailableCount) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "当周可安排数量",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.curRow.userFile.weekAvailableCount) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "当周已安排数量",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.curRow.userFile.weekAssignCount) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "当周空余数量",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.curRow.userFile.weekFreeCount) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "月总数量",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.curRow.userFile.monthAvailableCount) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    { attrs: { "label-width": _vm.labelWidth, label: "备注" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.curRow.userFile.commentText) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "收款账号",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.curRow.userFile.alipayAccount) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "擅长作品类型",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.curRow.userFile.goodAtProductionType) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "抵触作品类型",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.curRow.userFile.badAtProductionType) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "a-form-model",
        _vm._b(
          {
            ref: "dataForm",
            attrs: {
              slot: "form",
              model: _vm.formData,
              rules: _vm.validatorRules,
            },
            slot: "form",
          },
          "a-form-model",
          _vm.layout,
          false
        ),
        [
          _c("g-title", [_vm._v("基本信息")]),
          _c(
            "a-form-model-item",
            { attrs: { label: "昵称", prop: "nickName" } },
            [
              _c("a-input", {
                attrs: {
                  placeholder: "请输入昵称",
                  disabled: !_vm.allowEditBasicInfo,
                },
                model: {
                  value: _vm.formData.nickName,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "nickName", $$v)
                  },
                  expression: "formData.nickName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "登录邮箱", prop: "email" } },
            [
              _c("a-input", {
                attrs: {
                  placeholder: "请输入登录邮箱",
                  disabled: !_vm.allowEditBasicInfo,
                },
                model: {
                  value: _vm.formData.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "email", $$v)
                  },
                  expression: "formData.email",
                },
              }),
            ],
            1
          ),
          [
            _c(
              "a-form-model-item",
              { attrs: { label: "登录密码", prop: "password" } },
              [
                _c("a-input", {
                  attrs: {
                    type: "password",
                    placeholder: "请输入登录密码",
                    disabled: !_vm.allowEditBasicInfo,
                  },
                  model: {
                    value: _vm.formData.password,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "password", $$v)
                    },
                    expression: "formData.password",
                  },
                }),
              ],
              1
            ),
            _c(
              "a-form-model-item",
              { attrs: { label: "确认密码", prop: "confirmpassword" } },
              [
                _c("a-input", {
                  attrs: {
                    type: "password",
                    placeholder: "请重新输入登录密码",
                    disabled: !_vm.allowEditBasicInfo,
                  },
                  model: {
                    value: _vm.formData.confirmpassword,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "confirmpassword", $$v)
                    },
                    expression: "formData.confirmpassword",
                  },
                }),
              ],
              1
            ),
          ],
          _c(
            "a-form-model-item",
            { attrs: { label: "所属部门", prop: "selectedDepart" } },
            [
              _c("a-tree-select", {
                staticStyle: { width: "100%" },
                attrs: {
                  "tree-checkable": "",
                  disabled: !_vm.allowEditBasicInfo,
                  "tree-data": _vm.departList,
                  "search-placeholder": "选择所属部门",
                  placeholder: "请选择所属部门",
                },
                model: {
                  value: _vm.selectedDepartIds,
                  callback: function ($$v) {
                    _vm.selectedDepartIds = $$v
                  },
                  expression: "selectedDepartIds",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "职位" } },
            [
              _c(
                "a-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    mode: "multiple",
                    placeholder: "请选择职位",
                    optionFilterProp: "children",
                    disabled: !_vm.allowEditBasicInfo,
                    getPopupContainer: (target) => target.parentNode,
                  },
                  model: {
                    value: _vm.selectedPositionIds,
                    callback: function ($$v) {
                      _vm.selectedPositionIds = $$v
                    },
                    expression: "selectedPositionIds",
                  },
                },
                _vm._l(_vm.positionList, function (position, positionIndex) {
                  return _c(
                    "a-select-option",
                    {
                      key: positionIndex.toString(),
                      attrs: { value: position.id },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(position.name) +
                          "\n          "
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "角色" } },
            [
              _c(
                "a-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    mode: "multiple",
                    placeholder: "请选择用户角色",
                    optionFilterProp: "children",
                    disabled: !_vm.allowEditBasicInfo,
                    getPopupContainer: (target) => target.parentNode,
                  },
                  model: {
                    value: _vm.selectedRoleIds,
                    callback: function ($$v) {
                      _vm.selectedRoleIds = $$v
                    },
                    expression: "selectedRoleIds",
                  },
                },
                _vm._l(_vm.roleList, function (role, roleindex) {
                  return _c(
                    "a-select-option",
                    { key: roleindex.toString(), attrs: { value: role.id } },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(role.roleName) +
                          "\n          "
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "适用工具" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "适用工具", mode: "multiple" },
                  on: { change: _vm.onlyChange },
                  model: {
                    value: _vm.onlyjf,
                    callback: function ($$v) {
                      _vm.onlyjf = $$v
                    },
                    expression: "onlyjf",
                  },
                },
                [
                  _c(
                    "a-select-option",
                    { attrs: { value: "onlySimplified" } },
                    [_vm._v(" 质检大师简")]
                  ),
                  _c(
                    "a-select-option",
                    { attrs: { value: "onlyTraditional" } },
                    [_vm._v(" 质检大师繁")]
                  ),
                  _c("a-select-option", { attrs: { value: "onlyJapanese" } }, [
                    _vm._v(" 质检大师日"),
                  ]),
                  _c(
                    "a-select-option",
                    { attrs: { value: "onlyComparison" } },
                    [_vm._v(" 质检大师对比")]
                  ),
                  _c("a-select-option", { attrs: { value: "onlyOcr" } }, [
                    _vm._v(" 质检大师OCR"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("g-title", [_vm._v("个人档案")]),
          _c(
            "a-form-model-item",
            { attrs: { label: "头像" } },
            [
              _c(
                "a-upload",
                {
                  staticClass: "avatar-uploader",
                  attrs: {
                    name: "file",
                    "list-type": "picture-card",
                    "show-upload-list": false,
                    action: _vm.ossUploadUrl,
                    data: _vm.ossUploadData,
                    beforeUpload: _vm.beforeUpload,
                  },
                  on: { change: _vm.handleChangeUpload },
                },
                [
                  _vm.formData.userFile.avatar
                    ? _c("img", {
                        staticClass: "upload_img",
                        attrs: {
                          src: _vm.formData.userFile.avatar,
                          alt: "avatar",
                        },
                      })
                    : _c(
                        "div",
                        [
                          _c("a-icon", {
                            attrs: {
                              type: _vm.avatarLoading ? "loading" : "plus",
                            },
                          }),
                          _c("div", { staticClass: "ant-upload-text" }, [
                            _vm._v("Upload"),
                          ]),
                        ],
                        1
                      ),
                ]
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "姓名" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入姓名" },
                model: {
                  value: _vm.formData.userFile.userName,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData.userFile, "userName", $$v)
                  },
                  expression: "formData.userFile.userName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "性别" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "性别" },
                  model: {
                    value: _vm.formData.userFile.sex,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData.userFile, "sex", $$v)
                    },
                    expression: "formData.userFile.sex",
                  },
                },
                [
                  _c("a-select-option", { attrs: { value: 1 } }, [
                    _vm._v("男"),
                  ]),
                  _c("a-select-option", { attrs: { value: 2 } }, [
                    _vm._v("女"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "出生日期" } },
            [
              _c("a-date-picker", {
                attrs: { format: "YYYY-MM-DD" },
                model: {
                  value: _vm.formData.userFile.bornDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData.userFile, "bornDate", $$v)
                  },
                  expression: "formData.userFile.bornDate",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "国籍" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "国籍" },
                  model: {
                    value: _vm.formData.userFile.country,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData.userFile, "country", $$v)
                    },
                    expression: "formData.userFile.country",
                  },
                },
                [
                  _c("a-select-option", { attrs: { value: "中国" } }, [
                    _vm._v("中国"),
                  ]),
                  _c("a-select-option", { attrs: { value: "美国" } }, [
                    _vm._v("美国"),
                  ]),
                  _c("a-select-option", { attrs: { value: "英国" } }, [
                    _vm._v("英国"),
                  ]),
                  _c("a-select-option", { attrs: { value: "日本" } }, [
                    _vm._v("日本"),
                  ]),
                  _c("a-select-option", { attrs: { value: "韩国" } }, [
                    _vm._v("韩国"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "证件号" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入身份证号/护照号" },
                model: {
                  value: _vm.formData.userFile.idCardNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData.userFile, "idCardNo", $$v)
                  },
                  expression: "formData.userFile.idCardNo",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "QQ号" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入QQ号码或者其他联系方式" },
                model: {
                  value: _vm.formData.userFile.qq,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData.userFile, "qq", $$v)
                  },
                  expression: "formData.userFile.qq",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "电话号码" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入电话号码" },
                model: {
                  value: _vm.formData.userFile.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData.userFile, "phone", $$v)
                  },
                  expression: "formData.userFile.phone",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "学校" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入学校" },
                model: {
                  value: _vm.formData.userFile.school,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData.userFile, "school", $$v)
                  },
                  expression: "formData.userFile.school",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "签约类型" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "签约类型" },
                  model: {
                    value: _vm.formData.userFile.contractType,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData.userFile, "contractType", $$v)
                    },
                    expression: "formData.userFile.contractType",
                  },
                },
                [
                  _c("a-select-option", { attrs: { value: "全职" } }, [
                    _vm._v("全职"),
                  ]),
                  _c("a-select-option", { attrs: { value: "兼职" } }, [
                    _vm._v("兼职"),
                  ]),
                  _c("a-select-option", { attrs: { value: "意向全职" } }, [
                    _vm._v("意向全职"),
                  ]),
                  _c("a-select-option", { attrs: { value: "实习" } }, [
                    _vm._v("实习"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "签约日期" } },
            [
              _c("a-date-picker", {
                attrs: { format: "YYYY-MM-DD" },
                model: {
                  value: _vm.formData.userFile.contractDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData.userFile, "contractDate", $$v)
                  },
                  expression: "formData.userFile.contractDate",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "是否有试用期", prop: "haveProbation" } },
            [
              _c(
                "a-select",
                {
                  attrs: {
                    placeholder: "是否有试用期",
                    disabled: _vm.formData.userFile.contractType == "兼职",
                  },
                  model: {
                    value: _vm.formData.userFile.haveProbation,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData.userFile, "haveProbation", $$v)
                    },
                    expression: "formData.userFile.haveProbation",
                  },
                },
                [
                  _c("a-select-option", { attrs: { value: 1 } }, [
                    _vm._v("是"),
                  ]),
                  _c("a-select-option", { attrs: { value: 0 } }, [
                    _vm._v("否"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "试用期结束日期", prop: "probationEndDate" } },
            [
              _c("a-date-picker", {
                attrs: {
                  format: "YYYY-MM-DD",
                  disabled: _vm.formData.userFile.contractType == "兼职",
                },
                model: {
                  value: _vm.formData.userFile.probationEndDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData.userFile, "probationEndDate", $$v)
                  },
                  expression: "formData.userFile.probationEndDate",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.formData.userFile.contractType != "全职",
                  expression: "formData.userFile.contractType != '全职'",
                },
              ],
              attrs: { label: "是否有全职工作" },
            },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "是否有全职工作" },
                  model: {
                    value: _vm.formData.userFile.workStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData.userFile, "workStatus", $$v)
                    },
                    expression: "formData.userFile.workStatus",
                  },
                },
                [
                  _c("a-select-option", { attrs: { value: 1 } }, [
                    _vm._v("是"),
                  ]),
                  _c("a-select-option", { attrs: { value: 0 } }, [
                    _vm._v("否"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "可工作日期" } },
            [
              _c(
                "a-select",
                {
                  attrs: { mode: "multiple", placeholder: "可工作日期" },
                  model: {
                    value: _vm.formData.userFile.workDays,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData.userFile, "workDays", $$v)
                    },
                    expression: "formData.userFile.workDays",
                  },
                },
                [
                  _c("a-select-option", { attrs: { value: 1 } }, [
                    _vm._v("周一"),
                  ]),
                  _c("a-select-option", { attrs: { value: 2 } }, [
                    _vm._v("周二"),
                  ]),
                  _c("a-select-option", { attrs: { value: 3 } }, [
                    _vm._v("周三"),
                  ]),
                  _c("a-select-option", { attrs: { value: 4 } }, [
                    _vm._v("周四"),
                  ]),
                  _c("a-select-option", { attrs: { value: 5 } }, [
                    _vm._v("周五"),
                  ]),
                  _c("a-select-option", { attrs: { value: 6 } }, [
                    _vm._v("周六"),
                  ]),
                  _c("a-select-option", { attrs: { value: 7 } }, [
                    _vm._v("周日"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "工作日每天可安排数量" } },
            [
              _c("a-input-number", {
                attrs: { min: 0 },
                on: { change: _vm.workCountChange },
                model: {
                  value: _vm.formData.userFile.workdayAvailableCount,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.formData.userFile,
                      "workdayAvailableCount",
                      $$v
                    )
                  },
                  expression: "formData.userFile.workdayAvailableCount",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "周末两天可安排数量" } },
            [
              _c("a-input-number", {
                attrs: { min: 0 },
                on: { change: _vm.workCountChange },
                model: {
                  value: _vm.formData.userFile.weekendAvailableCount,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.formData.userFile,
                      "weekendAvailableCount",
                      $$v
                    )
                  },
                  expression: "formData.userFile.weekendAvailableCount",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "当周可安排数量" } },
            [
              _c("a-input-number", {
                attrs: { min: 0, disabled: true },
                model: {
                  value: _vm.formData.userFile.weekAvailableCount,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData.userFile, "weekAvailableCount", $$v)
                  },
                  expression: "formData.userFile.weekAvailableCount",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "当周已安排数量" } },
            [
              _c("a-input-number", {
                attrs: { min: 0, disabled: true },
                model: {
                  value: _vm.formData.userFile.weekAssignCount,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData.userFile, "weekAssignCount", $$v)
                  },
                  expression: "formData.userFile.weekAssignCount",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "当周空余数量" } },
            [
              _c("a-input-number", {
                attrs: { min: 0, disabled: true },
                model: {
                  value: _vm.formData.userFile.weekFreeCount,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData.userFile, "weekFreeCount", $$v)
                  },
                  expression: "formData.userFile.weekFreeCount",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "月总数量" } },
            [
              _c("a-input-number", {
                attrs: { min: 0, disabled: true },
                model: {
                  value: _vm.formData.userFile.monthAvailableCount,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData.userFile, "monthAvailableCount", $$v)
                  },
                  expression: "formData.userFile.monthAvailableCount",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "收款账号" } },
            [
              _c("a-textarea", {
                attrs: { autoSize: "", placeholder: "请输入收款账号" },
                model: {
                  value: _vm.formData.userFile.alipayAccount,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData.userFile, "alipayAccount", $$v)
                  },
                  expression: "formData.userFile.alipayAccount",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "擅长作品类型" } },
            [
              _c("a-textarea", {
                attrs: { autoSize: "", placeholder: "请输入擅长的作品类型" },
                model: {
                  value: _vm.formData.userFile.goodAtProductionType,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData.userFile, "goodAtProductionType", $$v)
                  },
                  expression: "formData.userFile.goodAtProductionType",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "抵触作品类型" } },
            [
              _c("a-textarea", {
                attrs: { autoSize: "", placeholder: "请输入抵触的作品类型" },
                model: {
                  value: _vm.formData.userFile.badAtProductionType,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData.userFile, "badAtProductionType", $$v)
                  },
                  expression: "formData.userFile.badAtProductionType",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "备注" } },
            [
              _c("a-textarea", {
                attrs: { autoSize: "", placeholder: "请输入备注" },
                model: {
                  value: _vm.formData.userFile.commentText,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData.userFile, "commentText", $$v)
                  },
                  expression: "formData.userFile.commentText",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }