<template>
  <drawer-layout
    :title="setTitle"
    :show="open"
    :isForm="isForm"
    permission="organization:user:list:button:edit"
    @show="show"
    @closed="closed"
    @save="saveForm"
    @edit="edit"
  >
    <div class="info-group">
      <g-title>基本信息</g-title>
      <info-item :label-width="labelWidth" label="名称">{{ curRow.nickName }}</info-item>
      <info-item :label-width="labelWidth" label="登录邮箱">{{ curRow.email }}</info-item>
      <!-- <info-item label="所属公司">
        <div class="flex flex-wrap pt-1">
          <a-tag v-for="(item, index) in curRow.companies" :key="index" class="mr-3 mb-3">{{ item.departName }}</a-tag>
        </div>
      </info-item> -->
      <info-item :label-width="labelWidth" label="所属部门">
        <div class="flex flex-wrap pt-1">
          <a-tag v-for="(item, index) in curRow.departs" :key="index" class="mr-3 mb-3">{{ item.departName }}</a-tag>
        </div>
      </info-item>
      <info-item :label-width="labelWidth" label="职位">
        <div class="flex flex-wrap pt-1">
          <a-tag v-for="(item, index) in curRow.positions" :key="index" class="mr-3 mb-3">{{ item.name }}</a-tag>
        </div>
      </info-item>
      <info-item :label-width="labelWidth" label="角色">
        <div class="flex flex-wrap pt-1">
          <a-tag v-for="(item, index) in curRow.roles" :key="index" class="mr-3 mb-3">{{ item.roleName }}</a-tag>
        </div>
      </info-item>
      <!--       <info-item
        :label-width="labelWidth"
        label="是否为质检员"
      >
        <div class="flex flex-wrap pt-1">
          {{ curRow.onlyTool | bool }}
        </div>
      </info-item> -->
      <info-item :label-width="labelWidth" label="适用工具">
        <div class="flex flex-wrap pt-1">
          <template v-for="(item, index) in onlyValue">
            <a-tag v-if="curRow[item.value]" :key="index" class="mr-3 mb-3">{{ item.label }}</a-tag>
          </template>
        </div>
      </info-item>
      <g-title>个人档案</g-title>
      <div v-if="curRow.userFile">
        <info-item :label-width="labelWidth" label="头像">
          <img class="upload_img" :src="curRow.userFile.avatar" />
        </info-item>
        <info-item :label-width="labelWidth" label="姓名">
          {{ curRow.userFile.userName }}
        </info-item>
        <info-item :label-width="labelWidth" label="性别">
          {{ curRow.userFile.sex == 1 ? '男' : '女' }}
        </info-item>
        <info-item :label-width="labelWidth" label="出生日期">
          {{ curRow.userFile.bornDate }}
        </info-item>
        <info-item :label-width="labelWidth" label="国籍">
          {{ curRow.userFile.country }}
        </info-item>
        <info-item :label-width="labelWidth" label="民族">
          {{ curRow.userFile.nation }}
        </info-item>
        <info-item :label-width="labelWidth" label="证件号">
          {{ curRow.userFile.idCardNo }}
        </info-item>
        <info-item :label-width="labelWidth" label="QQ号">
          {{ curRow.userFile.qq }}
        </info-item>
        <info-item :label-width="labelWidth" label="电话号码">
          {{ curRow.userFile.phone }}
        </info-item>
        <info-item :label-width="labelWidth" label="学历">
          {{ curRow.userFile.education }}
        </info-item>
        <info-item :label-width="labelWidth" label="学校">
          {{ curRow.userFile.school }}
        </info-item>
        <info-item :label-width="labelWidth" label="证书">
          {{ curRow.userFile.certificate }}
        </info-item>
        <info-item :label-width="labelWidth" label="工作经历">
          {{ curRow.userFile.workExperience }}
        </info-item>
        <info-item :label-width="labelWidth" label="工作地城市">
          {{ curRow.userFile.workCity }}
        </info-item>
        <info-item :label-width="labelWidth" label="签约类型">
          {{ curRow.userFile.contractType }}
        </info-item>
        <info-item :label-width="labelWidth" label="签约日期">
          {{ curRow.userFile.contractDate }}
        </info-item>
        <info-item :label-width="labelWidth" label="是否有试用期">
          {{ curRow.userFile.haveProbation ? '是' : '否' }}
        </info-item>
        <info-item :label-width="labelWidth" label="试用期结束日期">
          {{ curRow.userFile.probationEndDate }}
        </info-item>
        <info-item :label-width="labelWidth" label="是否有全职工作">
          {{ curRow.userFile.workStatus == 1 ? '是' : '否' }}
        </info-item>
        <info-item :label-width="labelWidth" label="可工作日期">
          {{ parseWorkDays(curRow.userFile.workDays) }}
        </info-item>
        <info-item :label-width="labelWidth" label="工作日每天可安排数量">
          {{ curRow.userFile.workdayAvailableCount }}
        </info-item>
        <info-item :label-width="labelWidth" label="周末两天可安排数量">
          {{ curRow.userFile.weekendAvailableCount }}
        </info-item>
        <info-item :label-width="labelWidth" label="当周可安排数量">
          {{ curRow.userFile.weekAvailableCount }}
        </info-item>
        <info-item :label-width="labelWidth" label="当周已安排数量">
          {{ curRow.userFile.weekAssignCount }}
        </info-item>
        <info-item :label-width="labelWidth" label="当周空余数量">
          {{ curRow.userFile.weekFreeCount }}
        </info-item>
        <info-item :label-width="labelWidth" label="月总数量">
          {{ curRow.userFile.monthAvailableCount }}
        </info-item>
        <info-item :label-width="labelWidth" label="备注">
          {{ curRow.userFile.commentText }}
        </info-item>
        <info-item :label-width="labelWidth" label="收款账号">
          {{ curRow.userFile.alipayAccount }}
        </info-item>
        <info-item :label-width="labelWidth" label="擅长作品类型">
          {{ curRow.userFile.goodAtProductionType }}
        </info-item>
        <info-item :label-width="labelWidth" label="抵触作品类型">
          {{ curRow.userFile.badAtProductionType }}
        </info-item>
      </div>
    </div>

    <a-form-model slot="form" :model="formData" ref="dataForm" :rules="validatorRules" v-bind="layout">
      <g-title>基本信息</g-title>
      <a-form-model-item label="昵称" prop="nickName">
        <a-input placeholder="请输入昵称" v-model="formData.nickName" :disabled="!allowEditBasicInfo" />
      </a-form-model-item>
      <a-form-model-item label="登录邮箱" prop="email">
        <a-input placeholder="请输入登录邮箱" v-model="formData.email" :disabled="!allowEditBasicInfo" />
      </a-form-model-item>
      <template>
        <a-form-model-item label="登录密码" prop="password">
          <a-input
            type="password"
            v-model="formData.password"
            placeholder="请输入登录密码"
            :disabled="!allowEditBasicInfo"
          />
        </a-form-model-item>

        <a-form-model-item label="确认密码" prop="confirmpassword">
          <a-input
            type="password"
            v-model="formData.confirmpassword"
            placeholder="请重新输入登录密码"
            :disabled="!allowEditBasicInfo"
          />
        </a-form-model-item>
      </template>
      <!-- <a-form-model-item label="所属公司" prop="selectedCompany">
        <a-tree-select
          v-model="selectedCompanyIds"
          style="width: 100%"
          tree-checkable
          :tree-data="companyList"
          search-placeholder="选择所属公司"
          placeholder="请选择所属公司"
        />
      </a-form-model-item> -->
      <a-form-model-item label="所属部门" prop="selectedDepart">
        <a-tree-select
          v-model="selectedDepartIds"
          style="width: 100%"
          tree-checkable
          :disabled="!allowEditBasicInfo"
          :tree-data="departList"
          search-placeholder="选择所属部门"
          placeholder="请选择所属部门"
        />
      </a-form-model-item>
      <a-form-model-item label="职位">
        <a-select
          mode="multiple"
          style="width: 100%"
          placeholder="请选择职位"
          optionFilterProp="children"
          :disabled="!allowEditBasicInfo"
          v-model="selectedPositionIds"
          :getPopupContainer="target => target.parentNode"
        >
          <a-select-option
            v-for="(position, positionIndex) in positionList"
            :key="positionIndex.toString()"
            :value="position.id"
          >
            {{ position.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="角色">
        <a-select
          mode="multiple"
          style="width: 100%"
          placeholder="请选择用户角色"
          optionFilterProp="children"
          :disabled="!allowEditBasicInfo"
          v-model="selectedRoleIds"
          :getPopupContainer="target => target.parentNode"
        >
          <a-select-option v-for="(role, roleindex) in roleList" :key="roleindex.toString()" :value="role.id">
            {{ role.roleName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <!--       <a-form-model-item label="是否为质检员">
        <a-radio-group v-model="formData.onlyTool">
          <a-radio :value="true">是</a-radio>
          <a-radio :value="false">否</a-radio>
        </a-radio-group>
      </a-form-model-item> -->
      <a-form-model-item label="适用工具">
        <a-select placeholder="适用工具" mode="multiple" v-model="onlyjf" @change="onlyChange">
          <a-select-option value="onlySimplified"> 质检大师简</a-select-option>
          <a-select-option value="onlyTraditional"> 质检大师繁</a-select-option>
          <a-select-option value="onlyJapanese"> 质检大师日</a-select-option>
          <a-select-option value="onlyComparison"> 质检大师对比</a-select-option>
          <a-select-option value="onlyOcr"> 质检大师OCR</a-select-option>
        </a-select>
      </a-form-model-item>

      <g-title>个人档案</g-title>
      <a-form-model-item label="头像">
        <a-upload
          name="file"
          list-type="picture-card"
          class="avatar-uploader"
          :show-upload-list="false"
          :action="ossUploadUrl"
          :data="ossUploadData"
          :beforeUpload="beforeUpload"
          @change="handleChangeUpload"
        >
          <img v-if="formData.userFile.avatar" :src="formData.userFile.avatar" class="upload_img" alt="avatar" />
          <div v-else>
            <a-icon :type="avatarLoading ? 'loading' : 'plus'" />
            <div class="ant-upload-text">Upload</div>
          </div>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="姓名">
        <a-input placeholder="请输入姓名" v-model="formData.userFile.userName" />
      </a-form-model-item>
      <a-form-model-item label="性别">
        <a-select placeholder="性别" v-model="formData.userFile.sex">
          <a-select-option :value="1">男</a-select-option>
          <a-select-option :value="2">女</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="出生日期">
        <a-date-picker format="YYYY-MM-DD" v-model="formData.userFile.bornDate" />
      </a-form-model-item>
      <a-form-model-item label="国籍">
        <a-select placeholder="国籍" v-model="formData.userFile.country">
          <a-select-option value="中国">中国</a-select-option>
          <a-select-option value="美国">美国</a-select-option>
          <a-select-option value="英国">英国</a-select-option>
          <a-select-option value="日本">日本</a-select-option>
          <a-select-option value="韩国">韩国</a-select-option>
        </a-select>
      </a-form-model-item>
<!--       <a-form-model-item label="民族">
        <a-auto-complete placeholder="民族" v-model="formData.userFile.nation">
          <template slot="dataSource">
            <a-select-option v-for="n in nationList" :key="n">{{ n }}</a-select-option>
          </template>
        </a-auto-complete>
      </a-form-model-item> -->
      <a-form-model-item label="证件号">
        <a-input placeholder="请输入身份证号/护照号" v-model="formData.userFile.idCardNo" />
      </a-form-model-item>
      <a-form-model-item label="QQ号">
        <a-input placeholder="请输入QQ号码或者其他联系方式" v-model="formData.userFile.qq" />
      </a-form-model-item>
      <a-form-model-item label="电话号码">
        <a-input placeholder="请输入电话号码" v-model="formData.userFile.phone" />
      </a-form-model-item>
<!--       <a-form-model-item label="学历">
        <a-select placeholder="学历" v-model="formData.userFile.education">
          <a-select-option value="博士">博士</a-select-option>
          <a-select-option value="硕士">硕士</a-select-option>
          <a-select-option value="本科">本科</a-select-option>
          <a-select-option value="专科">专科</a-select-option>
          <a-select-option value="高中">高中</a-select-option>
          <a-select-option value="初中">初中</a-select-option>
          <a-select-option value="小学">小学</a-select-option>
        </a-select>
      </a-form-model-item> -->
      <a-form-model-item label="学校">
        <a-input placeholder="请输入学校" v-model="formData.userFile.school" />
      </a-form-model-item>
<!--       <a-form-model-item label="职位相关证书">
        <a-input placeholder="请输入职位相关证书" v-model="formData.userFile.certificate" />
      </a-form-model-item> -->
<!--       <a-form-model-item label="工作经历">
        <a-textarea autoSize placeholder="请输入和现在职位相关的工作经历" v-model="formData.userFile.workExperience" />
      </a-form-model-item> -->
<!--       <a-form-model-item label="工作地城市">
        <a-input placeholder="请输入工作地城市" v-model="formData.userFile.workCity" />
      </a-form-model-item> -->
      <a-form-model-item label="签约类型">
        <a-select placeholder="签约类型" v-model="formData.userFile.contractType">
          <a-select-option value="全职">全职</a-select-option>
          <a-select-option value="兼职">兼职</a-select-option>
          <a-select-option value="意向全职">意向全职</a-select-option>
          <a-select-option value="实习">实习</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="签约日期">
        <a-date-picker format="YYYY-MM-DD" v-model="formData.userFile.contractDate" />
      </a-form-model-item>
      <a-form-model-item label="是否有试用期" prop="haveProbation">
        <a-select
          placeholder="是否有试用期"
          v-model="formData.userFile.haveProbation"
          :disabled="formData.userFile.contractType == '兼职'"
        >
          <a-select-option :value="1">是</a-select-option>
          <a-select-option :value="0">否</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="试用期结束日期" prop="probationEndDate">
        <a-date-picker
          format="YYYY-MM-DD"
          v-model="formData.userFile.probationEndDate"
          :disabled="formData.userFile.contractType == '兼职'"
        />
      </a-form-model-item>
      <a-form-model-item label="是否有全职工作" v-show="formData.userFile.contractType != '全职'">
        <a-select placeholder="是否有全职工作" v-model="formData.userFile.workStatus">
          <a-select-option :value="1">是</a-select-option>
          <a-select-option :value="0">否</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="可工作日期">
        <a-select mode="multiple" placeholder="可工作日期" v-model="formData.userFile.workDays">
          <a-select-option :value="1">周一</a-select-option>
          <a-select-option :value="2">周二</a-select-option>
          <a-select-option :value="3">周三</a-select-option>
          <a-select-option :value="4">周四</a-select-option>
          <a-select-option :value="5">周五</a-select-option>
          <a-select-option :value="6">周六</a-select-option>
          <a-select-option :value="7">周日</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="工作日每天可安排数量">
        <a-input-number :min="0" v-model="formData.userFile.workdayAvailableCount" @change="workCountChange" />
      </a-form-model-item>
      <a-form-model-item label="周末两天可安排数量">
        <a-input-number :min="0" v-model="formData.userFile.weekendAvailableCount" @change="workCountChange" />
      </a-form-model-item>
      <a-form-model-item label="当周可安排数量">
        <a-input-number :min="0" v-model="formData.userFile.weekAvailableCount" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="当周已安排数量">
        <a-input-number :min="0" v-model="formData.userFile.weekAssignCount" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="当周空余数量">
        <a-input-number :min="0" v-model="formData.userFile.weekFreeCount" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="月总数量">
        <a-input-number :min="0" v-model="formData.userFile.monthAvailableCount" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="收款账号">
        <a-textarea autoSize placeholder="请输入收款账号" v-model="formData.userFile.alipayAccount" />
      </a-form-model-item>
      <a-form-model-item label="擅长作品类型">
        <a-textarea autoSize placeholder="请输入擅长的作品类型" v-model="formData.userFile.goodAtProductionType" />
      </a-form-model-item>
      <a-form-model-item label="抵触作品类型">
        <a-textarea autoSize placeholder="请输入抵触的作品类型" v-model="formData.userFile.badAtProductionType" />
      </a-form-model-item>
      <a-form-model-item label="备注">
        <a-textarea autoSize placeholder="请输入备注" v-model="formData.userFile.commentText" />
      </a-form-model-item>
    </a-form-model>
  </drawer-layout>
</template>

<script>
import moment from 'moment'
import Vue from 'vue'
// 引入搜索部门弹出框的组件
import { USER_INFO } from '@/store/mutation-types'
import { addUser, editUser, queryUserRole, queryall } from '@/api/api'
import { duplicateCheck } from '@/api/api'
import { DrawerMixins } from '@/mixins/DrawerMixins'
import { UploadFileToOssMixins } from '@/mixins/UploadFileToOssMixins'

export default {
  name: 'UserModal',
  mixins: [DrawerMixins, UploadFileToOssMixins],
  components: {},
  data() {
    return {
      avatarLoading: false,
      modalWidth: 800,
      drawerWidth: 700,
      labelWidth: '160',
      userId: '', //保存用户id
      disableSubmit: false,
      dateFormat: 'YYYY-MM-DD',
      layout: {
        labelCol: {
          style: 'width: 160px',
          span: 7
        },
        wrapperCol: {
          span: 17
        }
      },
      validatorRules: {
        nickName: [
          {
            required: true,
            message: '请输入昵称!',
            trigger: 'blur'
          }
        ],
        email: [{ validator: this.validateEmail }],
        password: [{ validator: this.validateToNextPassword }],
        confirmpassword: [{ validator: this.compareToFirstPassword, trigger: 'blur' }],
        haveProbation: [{ validator: this.validateProbation }],
        probationEndDate: [{ validator: this.validateProbationEndDate }]
      },
      title: '操作',
      visible: false,
      formData: { userFile: {} },
      selectedCompanyIds: [],
      selectedDepartIds: [],
      selectedPositionIds: [],
      selectedRoleIds: [],
      uploadLoading: false,
      confirmLoading: false,
      nationList: [
        '汉族',
        '满族',
        '蒙古族',
        '回族',
        '藏族',
        '维吾尔族',
        '苗族',
        '彝族',
        '壮族',
        '布依族',
        '侗族',
        '瑶族',
        '白族',
        '土家族',
        '哈尼族',
        '哈萨克族',
        '傣族',
        '黎族',
        '傈僳族',
        '佤族',
        '畲族',
        '高山族',
        '拉祜族',
        '水族',
        '东乡族',
        '纳西族',
        '景颇族',
        '柯尔克孜族',
        '土族',
        '达斡尔族',
        '仫佬族',
        '羌族',
        '布朗族',
        '撒拉族',
        '毛南族',
        '仡佬族',
        '锡伯族',
        '阿昌族',
        '普米族',
        '朝鲜族',
        '塔吉克族',
        '怒族',
        '乌孜别克族',
        '俄罗斯族',
        '鄂温克族',
        '德昂族',
        '保安族',
        '裕固族',
        '京族',
        '塔塔尔族',
        '独龙族',
        '鄂伦春族',
        '赫哲族',
        '门巴族',
        '珞巴族',
        '基诺族'
      ],
      url: {
        edit: '/sys/user/edit_mx',
        add: '/sys/user/add_mx'
      },
      onlyjf: [],
      allowEditBasicInfo: false,
      onlyValue: [
        {
          value: 'onlySimplified',
          label: '质检大师简'
        },
        {
          value: 'onlyTraditional',
          label: '质检大师繁'
        },
        {
          value: 'onlyJapanese',
          label: '质检大师日'
        },
        {
          value: 'onlyComparison',
          label: '质检大师对比'
        },
        {
          value: 'onlyOcr',
          label: '质检大师OCR'
        }
      ]
    }
  },
  created() {
    this.userInfo = Vue.ls.get(USER_INFO)
    let roles = this.userInfo.roles || []
    roles.forEach(element => {
      if (['admin', 'boss', 'hr', 'tongchou'].indexOf(element.roleCode) >= 0) {
        this.allowEditBasicInfo = true
      }
    })
  },
  computed: {
    uploadAction: function() {
      return this.url.fileUpload
    }
  },
  methods: {
    onlyChange(value) {
      this.formData.onlySimplified = false
      this.formData.onlyTraditional = false
      this.formData.onlyJapanese = false
      this.formData.onlyComparison = false
      this.formData.onlyOcr= false
      if (value.length > 0) {
        value.forEach(key => {
          this.formData[key] = true
        })
      }
    },
    initForm() {
      this.selectedCompanyIds = []
      this.selectedDepartIds = []
      this.selectedPositionIds = []
      this.selectedRoleIds = []
      this.formData = {
        nickName: '',
        email: '',
        companies: [],
        departs: [],
        roles: [],
        positions: [],
        onlyTool: false,
        userFile: {}
      }
    },

    show() {
      console.log('显示...')
      this.getPosition()
      this.getDepartList()
      this.getRoles()
      this.onlyjf = []
      for (let key in this.curRow) {
        if (this.curRow[key]) {
          if (key == 'onlySimplified'||key == 'onlyTraditional'||key=="onlyJapanese"||key=="onlyComparison"||key=="onlyComparison") {
            this.onlyjf.push(key)
          }
        }
      }
    },

    edit() {
      this.isEdit = true
      this.isSaved = false
      if (!this.curRow.userFile) {
        this.curRow.userFile = {}
      }
      this.formData = this.curRow
      this.formData = Object.assign({}, this.formData, { email: this.curRow.email })
      const { companies, departs, positions, roles } = this.formData
      this.selectedCompanyIds = companies.map(item => item.id)
      this.selectedDepartIds = departs.map(item => item.id)
      this.selectedPositionIds = positions.map(item => item.id)
      this.selectedRoleIds = roles.map(item => item.id)
      this.oldFormData = JSON.parse(JSON.stringify(this.formData))
    },

    saveForm() {
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          //修改一下form的数据
          this.formData.selectedCompanyIds = this.selectedCompanyIds.join(',')
          this.formData.selectedDepartIds = this.selectedDepartIds.join(',')
          this.formData.selectedPositionIds = this.selectedPositionIds.join(',')
          this.formData.selectedRoleIds = this.selectedRoleIds.join(',')
          if (this.formData.id) {
            this.formData.userFile.userId = this.formData.id
          }
          if (this.formData.userFile.bornDate && this.formData.userFile.bornDate.format) {
            this.formData.userFile.bornDate = this.formData.userFile.bornDate.format(this.dateFormat)
          }
          if (this.formData.userFile.contractDate && this.formData.userFile.contractDate.format) {
            this.formData.userFile.contractDate = this.formData.userFile.contractDate.format(this.dateFormat)
          }
          if (this.formData.userFile.probationEndDate && this.formData.userFile.probationEndDate.format) {
            this.formData.userFile.probationEndDate = this.formData.userFile.probationEndDate.format(this.dateFormat)
          }
          this.save()
          if (this.formData.id == this.userInfo.id) {
            this.userInfo.userFile.avatar = this.formData.userFile.avatar
            Vue.ls.set(USER_INFO, this.userInfo, 7 * 24 * 60 * 60 * 1000)
          }
        }
      })
    },

    workCountChange() {
      let n1 = this.formData.userFile.workdayAvailableCount || 0
      let n2 = this.formData.userFile.weekendAvailableCount || 0
      this.formData.userFile.weekAvailableCount = n1 * 5 + n2
      this.formData.userFile.monthAvailableCount = this.formData.userFile.weekAvailableCount * 4
    },
    setFormData() {},
    moment,
    validateToNextPassword(rule, value, callback) {
      const confirmpassword = this.formData.confirmpassword

      if (value && confirmpassword && value !== confirmpassword) {
        callback('两次输入的密码不一样！')
      }
      callback()
    },
    compareToFirstPassword(rule, value, callback) {
      if (value && value !== this.formData.password) {
        callback('两次输入的密码不一样！')
      } else {
        callback()
      }
    },
    validateEmail(rule, value, callback) {
      if (this.isEdit) {
        callback()
        return
      }
      if (!value) {
        callback()
      } else {
        if (
          new RegExp(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(value)
        ) {
          var params = {
            tableName: 'sys_user',
            fieldName: 'email',
            fieldVal: value,
            dataId: this.userId
          }
          duplicateCheck(params).then(res => {
            console.log(res)
            if (res.success) {
              callback()
            } else {
              callback('邮箱已存在!')
            }
          })
        } else {
          callback('请输入正确格式的邮箱!')
        }
      }
    },
    validateProbation(rule, value, callback) {
      if (this.formData.userFile.contractType == '全职') {
        if (this.formData.userFile.haveProbation != '1') {
          callback('签约全职时必须选择试用期')
          return
        }
      }
      callback()
    },
    validateProbationEndDate(rule, value, callback) {
      if (this.formData.userFile.contractType == '全职') {
        if (!this.formData.userFile.probationEndDate) {
          callback('签约全职时必须选择试用期结束时间')
          return
        }
      }
      callback()
    },
    handleChangeUpload(info) {
      if (info.file.status === 'uploading') {
        this.avatarLoading = true
        return
      }
      if (info.file.status === 'done') {
        let params = this.getSaveFileParams(info.file)
        this.formData.userFile.avatar = params.filePath
        this.removeOssFilePolicy(info.file)
        this.avatarLoading = false
      }
    },
    parseWorkDays(workDays) {
      if (workDays) {
        let days = []
        const weeks = ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
        workDays.forEach(one => {
          if (one >= 1 && one <= 7) {
            days.push(weeks[one - 1])
          }
        })
        return days.join(',')
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped>
.avatar-uploader > .ant-upload {
  width: 104px;
  height: 104px;
}

.ant-upload-select-picture-card i {
  font-size: 49px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.ant-table-tbody .ant-table-row td {
  padding-top: 10px;
  padding-bottom: 10px;
}

.drawer-bootom-button {
  position: absolute;
  bottom: -8px;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 2px 2px;
}

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.upload_img {
  max-width: 120px;
}
</style>
